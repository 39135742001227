import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['one', 'two', 'last'];

  declare readonly oneTarget: HTMLElement;

  declare readonly twoTarget: HTMLElement;

  declare readonly lastTarget: HTMLElement;

  step(event: Event) {
    const target = event.target as HTMLElement;
    const nextStep = target.dataset.stepValue;
    this.HiddenAllContainers();
    console.log(target);
    console.log(nextStep);
    if (nextStep === 'one') {
      this.oneTarget.classList.remove('hidden');
    } else if (nextStep === 'two') {
      this.twoTarget.classList.remove('hidden');
    } else if (nextStep === 'last') {
      this.lastTarget.classList.remove('hidden');
    } else {
      console.log('no step');
    }
  }

  HiddenAllContainers() {
    this.oneTarget.classList.add('hidden');
    this.twoTarget.classList.add('hidden');
  }
}
