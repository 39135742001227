import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  declare readonly contentTarget: HTMLInputElement;

  toggle(event) {
    event.preventDefault();
    this.contentTarget.classList.toggle('hidden');
  }
}
