import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['sideNavigation', 'wrapper', 'overlay'];

  declare readonly sideNavigationTarget: HTMLElement;

  declare readonly wrapperTarget: HTMLElement;

  declare readonly overlayTarget: HTMLElement;

  closeModal() {
    const modals = document.querySelectorAll('[data-modal-target="modal"]');
    modals.forEach(modal => {
      modal.classList.add('hidden');
      modal.classList.remove('block');
    });
  }

  openMainNavigation() {
    console.log('test');
    this.sideNavigationTarget.style.width = '300px';
    this.sideNavigationTarget.style.display = 'block';

    this.wrapperTarget.style.marginRight = '300px';
    this.overlayTarget.classList.add('fixed');
    this.overlayTarget.classList.remove('hidden');
  }

  closeNavigation() {
    try {
      this.sideNavigationTarget.style.display = 'none';
      this.sideNavigationTarget.style.width = '0';
    } catch (e: unknown) {
      console.log(e);
    }
    this.wrapperTarget.style.marginRight = '0';
    this.overlayTarget.classList.add('hidden');
    this.overlayTarget.classList.remove('fixed');

    const dropdowns = document.querySelectorAll('.dropdown');
    for (const dropdown of dropdowns) {
      dropdown.classList.remove('is-active');
    }
  }
}
