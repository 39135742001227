import ujs from '@rails/ujs';

import '../assets/images';
import '@hotwired/turbo-rails';
import '@rails/actiontext';
import 'trix';

import * as activeStorage from '@rails/activestorage';

import './controllers/index.ts';
import './channels';

ujs.start();
activeStorage.start();
