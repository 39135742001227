import { Controller } from '@hotwired/stimulus';

declare global {
  interface Window {
    runSharePlus: {
      postMessage(message: unknown): void;
    };
  }
}

export default class extends Controller {
  static targets = ['modal'];

  declare readonly modalTarget: HTMLElement;

  declare readonly itemTitleValue: string;

  declare readonly itemUrlValue: string;

  static values = { elementId: String };

  openModal() {
    this.modalTarget.classList.add('flex');
    this.modalTarget.classList.remove('hidden');
  }

  openSharingModal() {
    if (window.navigator.userAgent.indexOf('Flutter') !== -1) {
      // モバイルアプリからアクセスされた場合は、Flutter側でシェア処理を行う
      window.runSharePlus.postMessage(window.location.href);
    } else if (navigator.share) {
      navigator.share({
        title: this.itemTitleValue,
        text: '',
        url: this.itemUrlValue,
      });
    } else {
      this.modalTarget.classList.add('flex');
      this.modalTarget.classList.remove('hidden');
    }
  }
}
